<template>
  <div>
    <div class="page-wrapper">
      <div class="page-content-wrapper">
        <div class="col-12 shadow-btm row">
          <div class="col-lg-6 col-sm-6 my-auto">
            <h4 class="mb-0 lg-bld">Manage Cities</h4>
          </div>
          <div class="col-6 text-right" v-show="accessObject?.is_add == 1">
            <a
              style="padding-left: 20px; padding-right: 20px; cursor: pointer"
              v-on:click="showCityManagePopup('ADD')"
              class="add-btn"
              >Add</a
            >
          </div>
        </div>
        <div class="page-content">
          <div class="card-body py-0 px-0">
            <div class="row">
              <div class="col-lg-6 col-sm-6 form-group row">
                <div class="has-search ml-3">
                  <i
                    class="fa fa-search"
                    style="position: absolute; margin: 10px"
                    aria-hidden="true"
                  ></i>
                  <input
                    type="text"
                    class="form-control"
                    v-on:keyup.enter="getList(0)"
                    placeholder="Search"
                    v-model="searchObj.keyword"
                  />
                </div>
                <a
                  class="search-btn btn-style cursor-pointer ml-3"
                  v-on:click="getList(0)"
                  >Search</a
                >
                <a
                  class="search-btn btn-style cursor-pointer"
                  v-on:click="resetFilter"
                  v-show="searchObj.keyword"
                  >Reset</a
                >
              </div>
            </div>
            <div class="card border mb-0 radius-15">
              <div class="card-body p-0 pb-4">
                <div class="table-responsive">
                  <div class="dataTables_wrapper dt-bootstrap4">
                    <table
                      class="table table-striped table-bordered dataTable"
                      style="width: 100%"
                      role="grid"
                      aria-describedby="example_info"
                    >
                      <thead>
                        <tr role="row">
                          <th
                            class="sorting"
                            id="id-name"
                            v-on:click="sorting('id', 'id-name')"
                          >
                            Sr No
                          </th>
                          <th
                            class="sorting"
                            id="id-name"
                            v-on:click="sorting('city_name', 'id-name')"
                          >
                            City Name
                          </th>
                          <th
                            v-if="
                              accessObject?.is_edit == 1 ||
                              accessObject?.is_delete == 1
                            "
                          >
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody v-show="!loader">
                        <tr v-for="(data, index) in list" :key="index">
                          <td>
                            {{
                              ($refs.listPagination.currentPage - 1) * 10 +
                              index +
                              1
                            }}
                          </td>
                          <td>{{ data.city_name }}</td>
                          <td
                            v-if="
                              accessObject?.is_edit == 1 ||
                              accessObject?.is_delete == 1
                            "
                          >
                            <a
                              v-if="accessObject?.is_edit == 1"
                              v-on:click="getCityDetail(data.id)"
                              class="edit-btn btn-style cursor-pointer"
                              >Edit</a
                            >
                            <a
                              v-if="accessObject?.is_delete == 1"
                              class="delete-btn btn-style cursor-pointer"
                              v-on:click="showDeletePopup(data)"
                              >Delete</a
                            >
                            <a
                              v-if="accessObject?.is_edit == 1"
                              class="edit-btn btn-style cursor-pointer"
                              v-on:click="$router.push({ path: '/merge-cities/' + data.id, query: { country_id: this.id } })"
                              >Merge Cities</a
                            >
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="row mx-0 mb-4">
                  <div class="col-lg-12 mt-5 text-center">
                    <div class="page-bottom-pagination text-right">
                      <Pagination
                        @page-change="pageChange"
                        @items-per-page-change="itemsPerPageChange"
                        ref="listPagination"
                      />
                    </div>
                  </div>
                </div>
                <ErrorComponent @retry="getList(0)" ref="errorComponent" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <DeleteModal
    @remove="deleteRecord($event)"
    @get-list="getList(0)"
    ref="deleteModal"
  />
  <TextModal ref="textModal">
    <div class="modal-header pt-0">
      <h5 class="modal-title">{{ detail.id ? "Edit" : "Add" }} City</h5>
      <button type="button" class="close" v-on:click="closeModal()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <Form ref="cityForm" @submit="save">
      <div class="col-lg-12 col-sm-12">
        <div class="form-group">
          <label>City Name</label>
          <span class="text-danger">*</span>
          <Field
            type="text"
            name="city_name"
            class="form-control"
            placeholder="City Name"
            v-model="detail.city_name"
            rules="required:city"
            :validateOnInput="true"
          />
          <ErrorMessage name="city_name" class="text-danger" />
        </div>
      </div>
      <div class="modal-footer pb-0">
        <button
          class="fill-btn"
          id="save-button"
          style="width: 20%; border-radius: 5px"
        >
          Save
        </button>
        <button
          type="button"
          class="fil-btn"
          id="cancel-button"
          v-on:click="closeModal()"
          style="width: 20%; border-radius: 5px"
        >
          Cancel
        </button>
      </div>
    </Form>
  </TextModal>
</template>
<script>
import ErrorComponent from "@/components/ErrorComponent";
import Pagination from "@/components/Pagination";
import DeleteModal from "@/components/DeleteModal";
import TextModal from "@/components/TextModal";
import { Form, Field, ErrorMessage } from "vee-validate";
export default {
  name: "Logs",
  components: {
    Form,
    Field,
    ErrorMessage,
    DeleteModal,
    TextModal,
    ErrorComponent,
    Pagination,
  },
  data() {
    return {
      id: this.$route.params.id,
      accessObject: this.$helperService.getAccessLevel("Manage Countries"),
      loader: true,
      searchObj: {},
      detail: {
        id: "",
        country_id: "",
      },
      offset: -1,
      list: [],
    };
  },
  mounted() {
    this.getList(0);
  },
  methods: {
    closeModal() {
      this.detail.id = "";
      this.$refs.textModal.closeModal();
    },
    showCityManagePopup() {
      this.$refs.textModal.showModal();
      this.$refs.cityForm.resetForm();
    },
    getCityDetail(id) {
      this.$api
        .getAPI({
          _action: "/city/detail/" + id,
        })
        .then((res) => {
          this.detail = res;
          this.$refs.textModal.showModal();
        })
        .catch(() => {});
    },
    save() {
      if (this.id) {
        this.detail.country_id = this.id;
      }
      if (this.detail.id) {
        this.$api
          .putAPI({
            _action: "/city",
            _body: this.detail,
            _buttonId: "save-button",
          })
          .then((res) => {
            this.detail.id = "";
            this.$notify({
              type: "success",
              text: res.message,
            });
            this.$refs.textModal.closeModal();
            if (this.offset == -1) {
              this.getList(0);
            } else {
              this.getList(this.offset - 1);
            }
          })
          .catch(() => {});
      } else {
        this.$api
          .postAPI({
            _action: "/city",
            _body: this.detail,
            _buttonId: "save-button",
          })
          .then((res) => {
            this.$router.go(-1);
            this.$notify({
              type: "success",
              text: res.message,
            });
            if (this.offset == -1) {
              this.getList(0);
            } else {
              this.getList(this.offset - 1);
            }
            this.$refs.textModal.closeModal();
          })
          .catch(() => {});
      }
    },
    pageChange(offset) {
      this.getList(offset - 1);
    },
    itemsPerPageChange() {
      this.itemsPerPage = this.$refs.listPagination.itemsPerPage;
      this.getList(0);
    },
    getList(page, orderBy, sortBy) {
      this.$refs.errorComponent.updateListLoader(true);
      this.list = [];
      this.searchObj.offset = page;
      if (orderBy) {
        this.searchObj.order_by = orderBy;
      }
      if (sortBy) {
        this.searchObj.sort_by = sortBy;
      }
      if (this.id) {
        this.searchObj.country_id = this.id;
      }
      this.$api
        .getAPI({
          _action: "/city/pagination/list",
          _body: this.searchObj,
        })
        .then((res) => {
          this.loader = false;
          this.list = res.list;
          this.offset = res.next_offset;
          this.accessObject =
            this.$helperService.getAccessLevel("Manage Countries");
          if (page == 0) {
            if (this.$refs.listPagination) {
              if (res.count != null && res.count > 0) {
                this.$refs.listPagination.setTotalCount(res.count);
              } else {
                this.$refs.listPagination.setTotalCount(0);
              }
            }
          }
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(res, "No Cities list available");
            this.$refs.errorComponent.updateListLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateListLoader(false);
          }
        });
    },
    sorting(sortBy, id) {
      var obj = this.$helperService.sorting(sortBy, id);
      this.getList(0, obj.order_by, obj.sort_by);
    },
    resetFilter() {
      this.searchObj.keyword = "";
      this.getList(0);
    },
    showDeletePopup(data) {
      this.$refs.deleteModal.showModal(
        "Delete City",
        "Are you sure you want to delete " + data.city_name,
        data
      );
    },
    deleteRecord(dataObj) {
      var detail = new Proxy(dataObj, {});
      this.$api
        .deleteAPI({
          _action: "/city/" + detail.id,
        })
        .then((res) => {
          if (res) {
            this.$refs.deleteModal.closeModal();
            if (this.offset == -1) {
              this.getList(0);
            } else {
              this.getList(this.offset - 1);
            }
            this.$notify({
              type: "success",
              text: res.message,
            });
          }
        })
        .catch(() => {
          this.$refs.deleteModal.removeLoader();
        });
    },
  },
};
</script>